import { isEqual } from 'lodash';

/**
 * Compares form previous and current order data and adds necessary updates:
 */
export function compareFormData(currentData, previousData) {
  // If nothing to compare, return the current data:
  if (!previousData) {
    return currentData;
  }
  const {
    offer: oldOffer,
    details: oldDetails,
    confirmationMailSentAt,
    staffingMailSentAt,
  } = previousData || {};
  const { shifts: oldShifts } = oldDetails || {};
  const { offer, details } = currentData || {};
  const { shifts } = details || {};
  const isConfirmationSent = !!confirmationMailSentAt;
  const isStaffingSent = !!staffingMailSentAt;
  // Has the offer items changed?
  const offerHasChanged = !isEqual(oldOffer, offer);
  // Has any nanny data been changed?
  let nannyDataHasChanged = false;
  shifts.forEach((shift, index) => {
    const { assignedTo: newNannies } = shift || {};
    const { assignedTo: oldNannies } = oldShifts[index] || {};
    if (!isEqual(oldNannies, newNannies)) {
      nannyDataHasChanged = true;
    }
  });
  return {
    ...currentData,
    ...(isConfirmationSent && offerHasChanged
      ? { shouldResendOfferMail: true }
      : {}),
    ...(isStaffingSent && nannyDataHasChanged
      ? { shouldResendStaffingMail: true }
      : {}),
  };
}

/**
 * Checks if shift dates are correct
 */
export function checkDates(value, index, allValues) {
  const { details } = allValues;
  const { shifts } = details;

  if (!value || (index !== 0 && !index)) {
    return 'Date is required';
  }
  const shift = shifts[index];
  const { start, end } = shift;
  const now = new Date();
  if (start && new Date(start) <= now) {
    return 'Start must be later than now.';
  }
  if (end && new Date(end) <= now) {
    return 'End must be later than now.';
  }
  if (start && end && new Date(start) >= new Date(end)) {
    return 'Start must be earlier than end.';
  }
  if (start && end && new Date(end) <= new Date(start)) {
    return 'End must be later than start.';
  }
  return undefined;
}

/**
 * Checks if all shifts along with their sessions are approved.
 */
export function checkShiftsApproval(shifts) {
  const shiftApprovals = shifts.map(shift => {
    const { sessionApprovals } = shift;
    if (!sessionApprovals) {
      return false;
    }
    if (shift.includesTravelTime) {
      // If the shift has travel time, then 3 approvals should exist, i.e 1 worked session and 2 travel sessions.
      return sessionApprovals.filter(s => s.isApproved).length === 3;
    }
    return sessionApprovals.every(s => s.isApproved);
  });
  return shiftApprovals.every(Boolean);
}
