import { Check, Close } from '@mui/icons-material';
import { useRecordContext } from 'react-admin';

export default function AutomaticBillingColumn() {
  const record = useRecordContext();

  const { shouldBillAutomatically } = record || {};

  return shouldBillAutomatically ? (
    <Check color="success" />
  ) : (
    <Close color="disabled" />
  );
}
