import './logging';
import {
  Admin,
  Resource,
  radiantDarkTheme,
  radiantLightTheme,
} from 'react-admin';
import { firebaseConfig } from './firebaseConfig';
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from 'react-admin-firebase';
import OrderList from './resources/orders/table/OrderList';
import CreateOrderForm from './resources/orders/forms/CreateOrderForm';
import EditOrderForm from './resources/orders/forms/EditOrderForm';

const dataProvider = FirebaseDataProvider(firebaseConfig, {
  softDelete: true,
});
const authProvider = FirebaseAuthProvider(firebaseConfig);

export default function App() {
  return (
    <Admin
      theme={radiantLightTheme}
      darkTheme={radiantDarkTheme}
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      <Resource
        name="orders"
        list={OrderList}
        create={CreateOrderForm}
        edit={EditOrderForm}
      />
    </Admin>
  );
}
