import { TextInput } from 'react-admin';
import { useTranslation } from 'react-i18next';

export default function NotesTab() {
  const { t } = useTranslation();

  return (
    <TextInput
      source="details.notes"
      fullWidth
      label={t('Notes')}
      multiline
      defaultValue={''}
      disabled={false}
    />
  );
}
