import StackdriverErrorReporter from 'stackdriver-errors-js';

const isProduction = process.env.NODE_ENV === 'production';

const STACKDRIVER_CONFIG = {
  key: process.env.REACT_APP_GOOGLE_API_KEY,
  projectId: 'care4kids-96e84',
  version: process.env.REACT_APP_VERSION,
  disabled: !isProduction,
  service: 'admin',
};

const errorHandler = new StackdriverErrorReporter();

errorHandler.start(STACKDRIVER_CONFIG);

export function logError(e) {
  if (!isProduction) {
    console.error(e);
  } else {
    errorHandler.report(e);
  }
}
