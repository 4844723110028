import {
  ArrayInput,
  BooleanInput,
  CheckboxGroupInput,
  DateField,
  FormDataConsumer,
  Labeled,
  NumberInput,
  SelectField,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  required,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import useOptions from '../../../hooks/useOptions';

export default function RequestTab({ record }) {
  const { t } = useTranslation();

  const { statuses, categories, languages } = useOptions();

  const { status } = record || {};

  const isBilled = status === 'billed';

  const dates = (
    <div style={styling.rowContainer}>
      <FormDataConsumer>
        {({ formData }) => (
          <>
            {formData?.createDate && (
              <Labeled label={t('Created on')}>
                <DateField source="createDate" label="" disabled={true} />
              </Labeled>
            )}
            {formData?.confirmationMailSentAt && (
              <Labeled label={t('Offer mail sent on')}>
                <DateField
                  source="confirmationMailSentAt"
                  label=""
                  disabled={true}
                />
              </Labeled>
            )}
          </>
        )}
      </FormDataConsumer>
    </div>
  );

  const orderStatus = status && (
    <div style={styling.rowContainer}>
      <Labeled label={t('Status')}>
        <SelectField
          source="status"
          label=""
          disabled={true}
          choices={statuses}
        />
      </Labeled>
    </div>
  );

  const category = (
    <div style={styling.rowContainer}>
      <SelectInput
        source="details.category"
        label={t('Category')}
        disabled={isBilled}
        choices={categories}
        validate={!isBilled && required()}
      />
    </div>
  );

  const childrenInformation = (
    <div style={styling.rowContainer}>
      <FormDataConsumer>
        {({ formData }) => {
          const { numberOfChildren } = formData?.details || {};
          const childrenIsOrAboveFive = numberOfChildren >= 5;
          const isRequired = childrenIsOrAboveFive && !isBilled && required();
          return (
            <>
              <NumberInput
                source="details.numberOfChildren"
                label={t('Number of children')}
                validate={isRequired}
                disabled={isBilled}
              />
              {childrenIsOrAboveFive && (
                <>
                  <TextInput
                    source="details.ageOfYoungestChild"
                    label={t('Age of youngest child')}
                    disabled={isBilled}
                    validate={isRequired}
                  />
                  <TextInput
                    source="details.ageOfOldestChild"
                    label={t('Age of oldest child')}
                    disabled={isBilled}
                    validate={isRequired}
                  />
                </>
              )}
            </>
          );
        }}
      </FormDataConsumer>
    </div>
  );

  const childrenList = (
    <div style={styling.rowContainer}>
      <FormDataConsumer>
        {({ formData }) => {
          const { numberOfChildren } = formData?.details || {};
          const childrenLessThanFive = numberOfChildren < 5;
          const isRequired = childrenLessThanFive && !isBilled && required();
          return (
            childrenLessThanFive && (
              <ArrayInput source="details.children" label={t('Children')}>
                <SimpleFormIterator
                  inline
                  disableReordering
                  disableAdd={isBilled}
                  disableRemove={isBilled}
                  sx={{ '.RaSimpleFormIterator-line': { border: 'none' } }}
                >
                  <TextInput
                    source="name"
                    label={t('Name')}
                    disabled={isBilled}
                    defaultValue={''}
                  />
                  <TextInput
                    source="age"
                    validate={isRequired}
                    label={t('Age')}
                    disabled={isBilled}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            )
          );
        }}
      </FormDataConsumer>
    </div>
  );

  const languageInformation = (
    <>
      <div style={styling.rowContainer}>
        <TextInput
          source="details.familyLanguage"
          label={t('Languages spoken')}
          validate={!isBilled && required()}
          disabled={isBilled}
        />
      </div>
      <div style={styling.rowContainer}>
        <CheckboxGroupInput
          source="details.preferredNannyLanguage"
          label={t('Language of nanny')}
          choices={languages}
          row={true}
          validate={!isBilled && required()}
          disabled={isBilled}
        />
      </div>
    </>
  );

  const petInformation = (
    <div style={styling.rowContainer}>
      <BooleanInput
        source="details.hasPets"
        label={t('Has pets')}
        disabled={isBilled}
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData?.details?.hasPets && (
            <TextInput
              source="details.petDetails"
              label={t('Please specify what pets you have.')}
              multiline
              disabled={isBilled}
            />
          )
        }
      </FormDataConsumer>
    </div>
  );

  const partyBox = (
    <div style={styling.rowContainer}>
      <BooleanInput
        source="details.withPartyBox"
        label={t('Party box requested')}
        disabled={isBilled}
      />
    </div>
  );

  const otherInformation = (
    <div style={styling.rowContainer}>
      <TextInput
        source="details.meetingDetails"
        label={t('Meeting details')}
        multiline
        defaultValue={''}
        fullWidth
        disabled={isBilled}
      />
      <TextInput
        multiline
        fullWidth
        label={t('Other important information')}
        source="details.otherImportantInfo"
        defaultValue={''}
        disabled={isBilled}
      />
    </div>
  );

  return (
    <>
      {dates}
      {orderStatus}
      {category}
      {childrenInformation}
      {childrenList}
      {languageInformation}
      {petInformation}
      {partyBox}
      {otherInformation}
    </>
  );
}

const styling = {
  rowContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    gap: '15px',
    padding: '5px 0px',
  },
};
