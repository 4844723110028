import { useEffect, useState } from 'react';
import { getShiftTypes } from '../api/shiftTypes';

/**
 * Returns shift types of a branch.
 */
export default function useShiftTypes(branchID) {
  const [shiftTypes, setShiftTypes] = useState();

  useEffect(() => {
    if (branchID) {
      getShiftTypes(branchID).then(response =>
        setShiftTypes(response?.data || []),
      );
    }
  }, [branchID]);

  return shiftTypes;
}
