import axios from 'axios';

/**
 * Sets up the Squadra api.
 */
export default function getSquadraAPI() {
  const companyID = process.env.REACT_APP_SQUADRA_COMPANY_ID;
  const companyApiKey = process.env.REACT_APP_SQUADRA_API_KEY;
  if (!companyApiKey || !companyID) {
    return null;
  }
  const host = 'https://europe-west6-squadra-47289.cloudfunctions.net';
  const baseURL = `${host}/api/v2`;
  const api = axios.create({
    baseURL,
    headers: {
      'x-api-key': companyApiKey,
    },
  });
  return api;
}
