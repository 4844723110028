import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { geohashForLocation } from 'geofire-common';
import { Autocomplete, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export default function PlaceField({ label, source, disabled, place }) {
  const { setValue } = useFormContext();

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const { formattedAddress } = place || {};

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey,
    options: {
      types: ['address'],
      componentRestrictions: { country: 'ch' },
    },
  });

  // Use @mui's Autocomplete since react-admin's Autocomplete does not work correctly with controlled inputs.
  return (
    <Autocomplete
      value={formattedAddress || ''} // Add a fallback to avoid "state change from controlled to uncontrolled" warning.
      disabled={disabled}
      loading={isPlacePredictionsLoading}
      sx={{ width: '30%' }}
      renderInput={params => (
        <TextField {...params} label={label} required={true} />
      )}
      options={placePredictions.map(p => ({
        ...p,
        id: p.place_id,
        label: p.description, // The name of the place is found in the description property of the usePlacesService webhook.
      }))}
      onSelect={e => getPlacePredictions({ input: e.target.value })}
      onChange={(event, value) => {
        const placeID = value?.place_id;
        // Get the latitude and longitude from placesService details. Taken from https://github.com/ErrorPro/react-google-autocomplete/issues/154#issuecomment-1108889602
        placesService.getDetails({ placeId: placeID }, details => {
          const latitude = details.geometry.location.lat();
          const longitude = details.geometry.location.lng();
          const hash = geohashForLocation([latitude, longitude]);
          const { description } = placePredictions.find(
            p => p.place_id === placeID,
          );
          const value = {
            name: description.replace(/,[^,]+$/, ''), // Remove part after last comma, i.e. the country.
            formattedAddress: description,
            googlePlaceID: placeID,
            latitude,
            longitude,
            hash,
          };
          setValue(source, value, { shouldDirty: true }); // Add shouldDirty flag since setValue doesn't dirty the field by default. Taken from https://github.com/react-hook-form/react-hook-form/issues/72#issuecomment-656500986
        });
      }}
    />
  );
}
