import { useTranslation } from 'react-i18next';

export default function useOptions() {
  const { t } = useTranslation();

  const statuses = [
    { id: 'requested', name: t('Requested'), color: 'default' },
    { id: 'pending', name: t('Pending'), color: 'default' },
    { id: 'cancelled', name: t('Cancelled'), color: 'default' },
    { id: 'confirmed', name: t('Confirmed'), color: 'default' },
    { id: 'staffing', name: t('Staffing'), color: 'default' },
    { id: 'staffed', name: t('Staffed'), color: 'default' },
    // { id: 'inProgress', name: t('In progress'), color: 'default' },
    { id: 'completed', name: t('Completed'), color: 'default' },
    { id: 'billed', name: t('Billed'), color: 'default' },
    // { id: 'paid', name: t('Paid'), color: 'default' },
  ];

  const languages = [
    { id: 'en', name: t('English') },
    { id: 'de', name: t('German') },
    { id: 'it', name: t('Italian') },
    { id: 'fr', name: t('French') },
    { id: 'es', name: t('Spanish') },
  ];

  const regions = [
    { name: t('Zurich city'), id: 'zurich' },
    { name: t('Right lakeside up to Herrliberg'), id: 'herrliberg' },
    { name: t('Left lakeside upto Horgen'), id: 'horgen' },
    {
      name: t('Regions Airport, Zurich NW (Unterland), Zurich SE (Oberland)'),
      id: 'airport',
    },
    { name: t('Other'), id: 'other' },
  ];

  const salutations = [
    {
      id: 'mr',
      name: t('Mr.'),
    },
    {
      id: 'ms',
      name: t('Ms.'),
    },
    {
      id: 'mrs',
      name: t('Mrs.'),
    },
    {
      id: 'family',
      name: t('Family'),
    },
  ];

  const categories = [
    {
      name: t('Private Family'),
      id: 'private',
    },
    {
      name: t('Party/Events'),
      id: 'partyEvents',
    },
    {
      name: t('Hotel'),
      id: 'hotel',
    },
  ];

  return { statuses, languages, regions, salutations, categories };
}
