import { Check, Close, WarningAmber } from '@mui/icons-material';
import { Button, useRecordContext, useRefresh } from 'react-admin';
import { useTranslation } from 'react-i18next';
import ResendComponent from '../../../../components/buttons/ResendComponent';
import { updateOrder } from '../../../../api/orders';
import { useState } from 'react';
import Loader from '../../../../components/Loader';

export default function OfferMailColumn({ setDialog }) {
  const { t } = useTranslation();
  const record = useRecordContext();
  const refresh = useRefresh();

  const { id, shouldResendOfferMail, confirmationMailSentAt, status } =
    record || {};

  const [isLoading, setLoading] = useState(false);

  const mailSent = !!confirmationMailSentAt;
  const statusWhichTriggersConfirmationMail = 'pending';

  if (mailSent) {
    return (
      <ResendComponent
        order={record}
        label={t('Re-send offer')}
        nextStatus={statusWhichTriggersConfirmationMail}
        otherUpdates={{
          shouldResendOfferMail: false,
          confirmedAt: '',
          cancelledAt: '',
        }} // Remove confirmedAt and cancelledAt timestamp to allow re-confirmation or re-cancellation.
        component={
          shouldResendOfferMail ? (
            <WarningAmber sx={{ color: 'red' }} />
          ) : (
            <Check color="success" />
          )
        }
        setDialog={setDialog}
      />
    );
  } else {
    return isLoading ? (
      <Loader />
    ) : status === 'requested' ? (
      <Button
        label={t('Send offer')}
        color="primary"
        variant="contained"
        onClick={async e => {
          e.stopPropagation();
          setDialog({
            isOpen: true,
            title: t('Send offer'),
            content: t('Are you sure you want to send the offer the client?'),
            onConfirm: async () => {
              setLoading(true);
              await updateOrder(id, {
                status: statusWhichTriggersConfirmationMail,
                shouldResendOfferMail: false,
              });
              setLoading(false);
              refresh();
              setDialog(false);
            },
          });
        }}
      />
    ) : (
      <Close color="disabled" />
    );
  }
}
