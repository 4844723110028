export function getPrices(items) {
  const vat = 0.081;
  const subTotal = getSubTotal(items);
  const vatOfSubTotal = subTotal * vat;
  const total = subTotal + vatOfSubTotal;
  return { subTotal, vatOfSubTotal, total };
}

export function getSubTotal(items) {
  const subTotal = items.reduce(
    (sum, item) => (sum += item.unitPrice * item.amount),
    0,
  );
  return subTotal;
}
