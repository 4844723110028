import { FunctionField } from 'react-admin';
import useOptions from '../../../../hooks/useOptions';
import { Chip } from '@mui/material';

export default function StatusColumn() {
  const { statuses } = useOptions();

  return (
    <FunctionField
      render={record => {
        const status = statuses.find(status => status.id === record.status);
        return <Chip color={status?.color} label={status?.name} />;
      }}
    />
  );
}
