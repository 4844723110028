import { logError } from '../../logging';
import getSquadraAPI from '../hooks/useSquadraAPI';

/**
 * Gets all the skills of care4kids in Squadra.
 */
export async function getSkills() {
  try {
    const api = getSquadraAPI();
    const skills = await api.get(`/skills`);
    return skills;
  } catch (e) {
    logError(e);
    return;
  }
}
