import { Button } from 'react-admin';
import { useRecordContext, useRefresh } from 'react-admin';
import { doc, getFirestore, updateDoc, arrayUnion } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { Check } from '@mui/icons-material';
import { isEqual } from 'lodash';

export default function CopyOfferButton({ source }) {
  const { t } = useTranslation();
  const record = useRecordContext();
  const refresh = useRefresh();

  const { id, billing, offer, status } = record || {};
  const { items: billingItems } = billing || {};
  const { items: offerItems } = offer || {};
  const offerItem = offerItems?.find(
    (item, index) => `offer.items.${index}.label` === source,
  );
  const isOfferCopied = billingItems?.find(item => isEqual(offerItem, item));
  const canBeCopied = offerItems?.length && !isOfferCopied;
  const billed = status === 'billed';

  // Copy offer item to billing item.
  const copyToDocAndRefresh = item => {
    const docRef = doc(getFirestore(), 'orders', id);
    updateDoc(docRef, { 'billing.items': arrayUnion(item) }).then(() =>
      refresh(),
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button
        variant="outlined"
        color="primary"
        label={
          isOfferCopied
            ? t('Copied to billing items')
            : t('Copy to billing items')
        }
        onClick={e => {
          e.stopPropagation();
          copyToDocAndRefresh(offerItem);
        }}
        disabled={!canBeCopied || billed}
        style={{ marginTop: '12px' }}
        alignIcon="right"
      >
        {isOfferCopied && <Check color="success" />}
      </Button>
    </div>
  );
}
