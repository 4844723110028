import {
  differenceInMilliseconds,
  hoursToMilliseconds,
  isSameDay,
} from 'date-fns';
import { minimumDurations, timeZone } from '../../../utils/constants';
import { toDate } from 'date-fns-tz';

export default function createOrderData(values) {
  const { details } = values;
  const { shifts, category } = details;
  // Add minimum paid duration to shifts:
  const shiftsWithMinimumPaidDuration = getShiftsWithMinimumPaidDuration(
    shifts,
    category,
  );
  // Add travel data to view commute time in Squadra:
  const shiftsWithTravelData = getShiftsWithTravelData(
    shiftsWithMinimumPaidDuration,
    values.offer.items.filter(item => item.type === 'travel'),
  );
  // Add number of nannies from offer items:
  const shiftsWithNumberOfNannies = getShiftsWithNumberOfNannies(
    shiftsWithTravelData,
    values.offer.items.filter(item => item.type === 'shift'),
  );
  // Convert the shift dates to Europe/Zurich time:
  const timeZonedShifts = getTimeZonedShifts(shiftsWithNumberOfNannies);
  values.details.shifts = timeZonedShifts;
  return values;
}

function getTimeZonedShifts(shifts) {
  return shifts.map(({ start, end, ...rest }) => ({
    start: toDate(new Date(start), { timeZone }),
    end: toDate(new Date(end), { timeZone }),
    ...rest,
  }));
}

function getShiftsWithNumberOfNannies(shifts, shiftOfferItems) {
  const newShifts = shifts.map(shift => {
    const offerItem = shiftOfferItems.find(
      item =>
        item.shift.start.valueOf() === shift.start.valueOf() &&
        item.shift.end.valueOf() === shift.end.valueOf(),
    );
    if (offerItem) {
      return {
        ...shift,
        numberOfNannies: offerItem.amount,
      };
    }
    return shift;
  });
  return newShifts;
}

function getShiftsWithMinimumPaidDuration(shifts, category) {
  const minimumPaidDurationForCategory = hoursToMilliseconds(
    minimumDurations[category],
  );
  // Go through each shift:
  return shifts.map(shift => {
    const shiftDuration = differenceInMilliseconds(shift.end, shift.start);
    return {
      ...shift,
      minimumPaidDuration:
        shiftDuration > minimumPaidDurationForCategory
          ? shiftDuration
          : minimumPaidDurationForCategory, // Add minimum paid duration for each shift since Squadra checks for worked vs minimum paid time.
    };
  });
}

function getShiftsWithTravelData(shifts, travelOfferItems) {
  const shiftsWithTravelData = shifts.map(s => {
    const shiftOfferItem = travelOfferItems.find(
      item =>
        isSameDay(item.shift.start, s.start) &&
        isSameDay(item.shift.end, s.end),
    );
    if (shiftOfferItem) {
      const { includesTravelTime, travelTime } = shiftOfferItem.shift || {};
      return {
        ...s,
        includesTravelTime,
        travelTime,
      };
    }
    return s;
  });
  return shiftsWithTravelData;
}
