import { Check, Close, WarningAmber } from '@mui/icons-material';
import { Button, useRecordContext, useRefresh } from 'react-admin';
import { useTranslation } from 'react-i18next';
import ResendComponent from '../../../../components/buttons/ResendComponent';
import { updateOrder } from '../../../../api/orders';
import { useState } from 'react';
import Loader from '../../../../components/Loader';

export default function StaffingMailColumn({ setDialog }) {
  const { t } = useTranslation();
  const record = useRecordContext();
  const refresh = useRefresh();

  const { id, shouldResendStaffingMail, staffingMailSentAt, details } =
    record || {};
  const { shifts } = details || {};

  const [isLoading, setLoading] = useState(false);

  const mailSent = !!staffingMailSentAt;
  const hasNannyAssigned = shifts?.find(s => s.assignedTo?.length > 0);
  const statusWhichTriggersStaffingMail = 'staffed';

  if (mailSent) {
    return shouldResendStaffingMail ? (
      <ResendComponent
        order={record}
        label={t('Re-send staffing mail')}
        nextStatus={statusWhichTriggersStaffingMail}
        otherUpdates={{ shouldResendStaffingMail: false }}
        component={<WarningAmber sx={{ color: 'red' }} />}
        setDialog={setDialog}
      />
    ) : (
      <Check color="success" />
    );
  } else {
    return isLoading ? (
      <Loader />
    ) : hasNannyAssigned ? (
      <Button
        label={t('Send confirmation')}
        color="primary"
        variant="contained"
        onClick={async e => {
          e.stopPropagation();
          setDialog({
            isOpen: true,
            title: t('Send confirmation'),
            content: t(
              'Are you sure you want to send confirmation to the client?',
            ),
            onConfirm: async () => {
              setLoading(true);
              await updateOrder(id, {
                status: 'staffed',
                shouldResendStaffingMail: false,
              });
              setLoading(false);
              refresh();
              setDialog(false);
            },
          });
        }}
      />
    ) : (
      <Close color="disabled" />
    );
  }
}
