import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { Button, FunctionField, useRedirect, useRefresh } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { checkShiftsApproval } from '../../../../utils/order';

export default function ActionsColumn({ setDialog }) {
  const { t } = useTranslation();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const updateOrder = (docID, updates) => {
    const docRef = doc(getFirestore(), 'orders', docID);
    updateDoc(docRef, updates).then(() => refresh());
  };

  return (
    <FunctionField
      render={record => {
        const { id, status, billing, details } = record || {};
        const canBeCancelled = ['requested', 'pending', 'staffing'].includes(
          status,
        );
        const isCancelled = record?.status === 'cancelled';
        const isBilled = record?.status === 'billed';
        const hasBillingItems = billing?.items?.length;
        const areAllShiftsApproved =
          details?.shifts?.length && checkShiftsApproval(details.shifts);
        const canPrepareInvoice =
          !isBilled && areAllShiftsApproved && hasBillingItems;
        return (
          <div className="flex flex-col gap-2">
            {canPrepareInvoice && (
              <Button
                label={t('Prepare Invoice')}
                variant="contained"
                color="primary"
                onClick={async e => {
                  e.stopPropagation();
                  redirect(`${id}/4`); // Open the billing tab of this order.
                }}
              />
            )}
            {isCancelled ? (
              <Button
                label={t('Undo Cancel')}
                color="primary"
                onClick={e => {
                  e.stopPropagation();
                  setDialog({
                    isOpen: true,
                    title: t('Undo cancel'),
                    content: t(
                      'Are you sure you want to undo a cancel on this order?',
                    ),
                    onConfirm: () => {
                      updateOrder(id, {
                        status: 'requested',
                        cancelledAt: '',
                        confirmedAt: '',
                      }); // Reset cancelledAt and confirmedAt timestamp to cancel or confirm the order again.
                      setDialog(false);
                    },
                  });
                }}
              />
            ) : (
              canBeCancelled && (
                <Button
                  label={t('Cancel')}
                  color="error"
                  onClick={e => {
                    e.stopPropagation();
                    setDialog({
                      isOpen: true,
                      title: t('Cancel order'),
                      content: t('Are you sure you want to cancel this order?'),
                      onConfirm: () => {
                        updateOrder(id, { status: 'cancelled' });
                        setDialog(false);
                      },
                    });
                  }}
                />
              )
            )}
          </div>
        );
      }}
    />
  );
}
