import {
  ArrayInput,
  AutocompleteArrayInput,
  Button,
  DateField,
  DateTimeInput,
  FormDataConsumer,
  Labeled,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  TextField,
  required,
  NumberField,
  SelectField,
  ArrayField,
  Datagrid,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import useSkills from '../../../squadra/hooks/useSkills';
import useShiftTypes from '../../../squadra/hooks/useShiftTypes';
import PlaceField from '../../../fields/PlaceField';
import { useFormContext } from 'react-hook-form';
import CreateJobsButton from '../../../components/buttons/CreateJobsButton';
import { useState } from 'react';
import { checkDates } from '../../../utils/order';
import { timeZone } from '../../../utils/constants';
import Loader from '../../../components/Loader';

export default function StaffingTab({ record }) {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const branchID = process.env.REACT_APP_SQUADRA_BRANCH_ID;
  const skills = useSkills();
  const shiftTypes = useShiftTypes(branchID);

  const [isLoading, setLoading] = useState(false);

  const { status, details } = record || {};
  const { shifts } = details || {};

  const isBilled = status === 'billed';

  const dates = (
    <FormDataConsumer>
      {({ formData }) =>
        formData?.staffingMailSentAt && (
          <Labeled label={t('Confirmation sent on')}>
            <DateField source="staffingMailSentAt" label="" disabled={true} />
          </Labeled>
        )
      }
    </FormDataConsumer>
  );

  const getAssignedNannies = getSource => (
    <Labeled label={t('Assigned to')}>
      <ArrayField source={getSource('assignedTo')}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="phone" />
        </Datagrid>
      </ArrayField>
    </Labeled>
  );

  const shiftItemsList = (
    <ArrayInput label="" source="details.shifts" validate={required()}>
      <SimpleFormIterator
        inline
        disableAdd={isBilled}
        disableRemove={isBilled}
        disableReordering={isBilled}
        addButton={<Button label={t('Add item')} />}
        removeButton={<Button color="error" label={t('Remove item')} />}
      >
        <FormDataConsumer>
          {({ formData, scopedFormData, getSource, ...rest }) => {
            const { squadraJobID, place, assignedTo, requiredSkills } =
              scopedFormData;
            const shiftIndex = getSource().split('.')[2];
            const isJobCreated = !!squadraJobID;
            const hasAssignedNanny = !!assignedTo?.length;
            const skillNames = requiredSkills?.length
              ? skills &&
                skills
                  .filter(({ id }) => requiredSkills.find(rS => rS === id))
                  .map(({ name }) => name)
              : ['None'];
            return (
              <div style={styling.rowContainer}>
                {isJobCreated ? (
                  <>
                    <Labeled>
                      <DateField
                        source={getSource('start')}
                        {...rest}
                        showTime
                        options={{ timeZone }}
                      />
                    </Labeled>
                    <Labeled>
                      <DateField
                        source={getSource('end')}
                        {...rest}
                        showTime
                        options={{ timeZone }}
                      />
                    </Labeled>
                    <Labeled label={t('Place')}>
                      <TextField
                        source={getSource('place.formattedAddress')}
                        {...rest}
                      />
                    </Labeled>
                    <Labeled>
                      <NumberField
                        source={getSource('numberOfNannies')}
                        {...rest}
                      />
                    </Labeled>
                    <Labeled>
                      <SelectField
                        label={t('Type')}
                        choices={shiftTypes.sort((a, b) =>
                          a.name.localeCompare(b.name),
                        )}
                        source={getSource('type')}
                        emptyText={t('None')}
                      />
                    </Labeled>
                    <Labeled label={t('Skills')}>
                      <TextField emptyText={skillNames.join(',')} />
                    </Labeled>
                  </>
                ) : (
                  <>
                    <DateTimeInput
                      source={getSource('start')}
                      {...rest}
                      sx={{ width: '30%' }}
                      validate={[
                        (val, allVal, fieldData) => {
                          const index = Number(fieldData.source.split('.')[2]);
                          return checkDates(val, index, allVal);
                        },
                      ]}
                    />
                    <DateTimeInput
                      source={getSource('end')}
                      {...rest}
                      sx={{ width: '30%' }}
                      validate={[
                        (val, allVal, fieldData) => {
                          const index = Number(fieldData.source.split('.')[2]);
                          return checkDates(val, index, allVal);
                        },
                      ]}
                    />
                    <PlaceField
                      label={t('Place')}
                      source={getSource('place')}
                      {...rest}
                      place={place}
                      disabled={isJobCreated}
                    />
                    <NumberInput
                      source={getSource('numberOfNannies')}
                      label={t('Number of nannies')}
                      {...rest}
                      disabled={isJobCreated}
                      sx={{ width: '30%' }}
                    />
                    <SelectInput
                      label={t('Type')}
                      choices={shiftTypes.sort((a, b) =>
                        a.name.localeCompare(b.name),
                      )}
                      source={getSource('type')}
                      sx={{ width: '30%' }}
                      defaultValue={''}
                      onChange={e => {
                        // Populate the required skills field if the selected shift type has required skills.
                        const shiftTypeID = e.target.value;
                        const { requiredSkills } = shiftTypes.find(
                          ({ id }) => id === shiftTypeID,
                        );
                        if (requiredSkills?.length) {
                          setValue(getSource('requiredSkills'), requiredSkills);
                        }
                      }}
                    />
                    <AutocompleteArrayInput
                      label={t('Skills')}
                      source={getSource('requiredSkills')}
                      choices={skills.sort((a, b) =>
                        a.name.localeCompare(b.name),
                      )}
                      sx={{ width: '30%' }}
                      disabled={isJobCreated}
                    />
                  </>
                )}
                {hasAssignedNanny ? getAssignedNannies(getSource) : null}
                {shifts?.length && shiftIndex && shifts[shiftIndex] && (
                  <CreateJobsButton
                    order={record}
                    shiftIndex={Number(shiftIndex)}
                    isLoading={isLoading}
                    setLoading={setLoading}
                  />
                )}
              </div>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );

  if (!shiftTypes || !skills) {
    return (
      <div style={styling.centered}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      {dates}
      <CreateJobsButton
        order={record}
        isLoading={isLoading}
        setLoading={setLoading}
      />
      {shiftItemsList}
    </>
  );
}

const styling = {
  arrayFieldContainer: {
    border: '1px solid lightGray',
    borderRadius: '5px',
    padding: '15px',
    margin: '5px 0',
  },
  rowContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px 50px',
    padding: '20px',
    margin: '20px 20px 20px 0px',
    border: '1px solid lightgray',
    borderRadius: '10px',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
