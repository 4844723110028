import { updateOrder } from '../api/orders';

/**
 * Resets the order status to desired state:
 */
export async function handleStatusReset(orderID, status, otherUpdates) {
  const restStatus = 'reset';
  // Update the order to a rest status so no trigger is caused:
  await updateOrder(orderID, { status: restStatus });
  // Update the status of the order to trigger a resulting response:
  return updateOrder(orderID, {
    status,
    ...(otherUpdates ? { ...otherUpdates } : {}),
  });
}
