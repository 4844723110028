import { FunctionField, TextField, WrapperField } from 'react-admin';

export default function OrderedByColumn() {
  const companyField = <TextField source="billing.contact.company" />;
  const orderedByName = (
    <WrapperField>
      <TextField
        source="billing.contact.firstName"
        sx={{ marginRight: '5px' }}
      />
      <TextField source="billing.contact.lastName" />
    </WrapperField>
  );
  return (
    <FunctionField
      render={record => {
        const companyName = record?.billing?.contact?.company;
        return companyName ? companyField : orderedByName;
      }}
    />
  );
}
