import { logError } from '../../logging';
import getSquadraAPI from '../hooks/useSquadraAPI';

/**
 * Get a job.
 */
export async function getJob(jobID) {
  try {
    const api = getSquadraAPI();
    const response = await api.get(`/jobs/${jobID}`);
    const job = response.data;
    if (!job) {
      throw new Error(`Could not get job with ID ${jobID}`);
    }
    return job;
  } catch (e) {
    logError(e);
    return;
  }
}

/**
 * Create a job in Squadra.
 */
export async function createJob(
  shift,
  employeeIDs,
  category,
  jobGroupID,
  order,
  createdBy,
) {
  try {
    const api = getSquadraAPI();
    const jobData = makeJobData(
      shift,
      employeeIDs,
      category,
      jobGroupID,
      order,
      createdBy,
    );
    const response = await api.post('/jobs', jobData);
    const jobID = response.data;
    if (!jobID) {
      throw new Error(`Could not create job from shift ${shift.id}`);
    }
    return jobID;
  } catch (e) {
    logError(e);
    return;
  }
}

function makeJobData(
  shift,
  employeeIDs,
  category,
  jobGroupID,
  order,
  createdBy,
) {
  const { id, company, branch, start, end, type, positions } = shift;
  return {
    company,
    branch,
    createdBy,
    shift: id,
    shiftType: type,
    isPublished: false,
    isOpen: false,
    isDeleted: false,
    visibleTo: employeeIDs,
    visibility: 'selectedEmployees',
    numApplications: 0,
    group: jobGroupID,
    start,
    end,
    positions,
    title: getJobTitle(category),
    description: makeJobDescription(order),
    terms:
      'Dieser Einsatzvertrag gemäss AVG für Kinderbetreuung ist ein Zusatz zum Leih- und Temporäre-Rahmenarbeitsvertrag zwischen dem Arbeitgeber und dem / der Arbeitnehmer/in für den kurzfristigen und temporären Einsatz in einem Kundenbetrieb der Verleihfirma. Dauert e/in Arbeitseinsatz nicht länger als 6 Stunden kann bei zeitlicher Dringlichkeit vom Abschluss eines schriftlichen Vertrages ganz abgesehen werden. Ausgenommen von der Lohnzahlungspflicht des Verleihers sind Fälle, in welchen die Nanny die nicht erfolgte Arbeit zu verantworten hat. Für den Einsatz gelten die Bestimmungen des Rahmenarbeitsvertrages, welche um die Konditionen diese Vertrags ergänzt werden. Der Rahmenarbeitsvertrag tritt erst durch die gegenseitige Bestätigung dieses konkreten Einsatzvertrages in Kraft und bildet dann einen integrierenden Bestandteil dieses Vertrages.',
  };
}

function getJobTitle(category) {
  return category === 'private'
    ? 'Nanny for a private family'
    : category === 'partyEvents'
    ? 'Nanny for a party/event'
    : 'Nanny for a hotel';
}

const fallbackLanguages = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Deutsch',
    value: 'de',
  },
  {
    label: 'Español',
    value: 'es',
  },
  {
    label: 'Français',
    value: 'fr',
  },
  {
    label: 'Italiano',
    value: 'it',
  },
];

function makeJobDescription(order) {
  const { details } = order || {};
  const {
    children,
    hasPets,
    petDetails,
    numberOfChildren,
    ageOfOldestChild,
    otherImportantInfo,
    ageOfYoungestChild,
    serviceLanguage,
    familyLanguage,
    preferredNannyLanguage,
  } = details || {};
  const isEnglish = serviceLanguage === 'en';
  return `${isEnglish ? 'Children' : 'Kinder'}: ${
    numberOfChildren > 4
      ? `${numberOfChildren}, ${ageOfOldestChild} - ${ageOfYoungestChild}`
      : children?.map(c => `${c.name} ${c.age}`).join(',')
  }
    ${hasPets ? `${isEnglish ? 'Pets' : 'Haustiere'}: ${petDetails}` : ''}
    ${`${
      isEnglish ? 'Family language' : 'Sprache der Familie'
    }: ${familyLanguage}`}
    ${
      preferredNannyLanguage.length
        ? `${
            isEnglish
              ? 'Preferred language of nanny'
              : 'Bevorzugte Sprache der Nanny'
          }: ${preferredNannyLanguage
            .map(l => fallbackLanguages.find(obj => obj.value === l)?.label)
            .join(', ')}`
        : ''
    }
    ${
      otherImportantInfo
        ? `${
            isEnglish ? 'Important information' : 'Wichtige Informationen'
          }: ${otherImportantInfo}`
        : ''
    }`;
}
