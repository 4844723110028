import { Button, useRefresh } from 'react-admin';
import { handleStatusReset } from '../../utils/mail';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';

/**
 * By default shows the passed component and if hovered shows a resend button.
 */
export default function ResendComponent({
  order,
  label,
  nextStatus,
  otherUpdates,
  component,
  setDialog,
}) {
  const { t } = useTranslation();
  const refresh = useRefresh();

  const { id, status } = order || {};
  const isBilled = status === 'billed';
  const isCancelled = status === 'cancelled';

  const [isLoading, setLoading] = useState(false);

  // Add hover class to component:
  if (component && !isCancelled) {
    const { props } = component;
    const { className } = props || {};
    component = {
      ...component,
      props: {
        ...props,
        className: `${className} group-hover:hidden`,
      },
    };
    component.props['className'] = 'group-hover:hidden';
  }

  return (
    // Add fixed width to avoid pushing other components like columns on a table.
    <div className={`w-40 ${!isBilled ? 'group' : ''}`}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {component}
          {!isBilled && !isCancelled && (
            <Button
              label={label}
              color="primary"
              variant="contained"
              className="invisible group-hover:visible"
              onClick={async e => {
                e.stopPropagation();
                setDialog({
                  isOpen: true,
                  title: t('Re-send'),
                  content: t('Are you sure you want to re-send?'),
                  onConfirm: async () => {
                    setLoading(true);
                    await handleStatusReset(id, nextStatus, otherUpdates);
                    setLoading(false);
                    refresh();
                    setDialog(false);
                  },
                });
              }}
            />
          )}
        </>
      )}
    </div>
  );
}
