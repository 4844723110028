import * as React from 'react';
import { Create } from 'react-admin';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import OrderForm from './OrderForm';
import { logError } from '../../../logging';
import { parseWithDateReviver } from '../../../utils/dates';
import createOrderData from './createOrderData';
import { toDate } from 'date-fns-tz';
import { timeZone, initialStatus } from '../../../utils/constants';

export default function CreateOrderForm() {
  async function createAndSaveOfferItems(values) {
    try {
      // Add default timezone:
      values.details.serviceTimeZone = timeZone;
      const offerItems = await getOfferItems(values);
      if (!offerItems) {
        throw new Error('Could not get offer items.');
      }
      values.offer.items = offerItems;
      const data = {
        ...values,
        createDate: toDate(new Date(), { timeZone }),
        status: initialStatus,
        deleted: false,
      };
      const orderData = createOrderData(data);
      return orderData;
    } catch (e) {
      logError(e);
      return;
    }
  }
  return (
    <Create transform={createAndSaveOfferItems}>
      <OrderForm />
    </Create>
  );
}

async function getOfferItems(values) {
  try {
    const { category, shifts, numberOfChildren } = values.details || {};
    if (!(category && shifts?.length && numberOfChildren)) {
      throw new Error(
        'Offer items could not be calculated. Insufficient data.',
      );
    }
    const functions = getFunctions(getApp(), 'europe-west6');
    const getOfferItems = httpsCallable(functions, 'https-getOfferItems');
    const { data: offerItems } = await getOfferItems(values);
    if (!offerItems || !offerItems?.length) {
      throw new Error('Error when calculating offer items.');
    }
    return parseWithDateReviver(offerItems);
  } catch (error) {
    logError(error);
    return;
  }
}
