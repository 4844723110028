import { getAuth } from 'firebase/auth';
import { doc, getFirestore, onSnapshot, updateDoc } from 'firebase/firestore';

export function updateOrder(orderID, updates) {
  const docRef = doc(getFirestore(), 'orders', orderID);
  return updateDoc(docRef, {
    ...updates,
    lastUpdate: new Date(),
    updatedBy: getAuth().currentUser.uid,
  });
}

export function listenToOrder(orderID, handleNewData) {
  const docRef = doc(getFirestore(), 'orders', orderID);
  return onSnapshot(
    docRef,
    snapshot => {
      if (snapshot.exists()) {
        handleNewData({ id: snapshot.id, ...snapshot.data() });
      }
    },
    e => console.error(e),
  );
}
