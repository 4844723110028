import { logError } from '../../logging';
import getSquadraAPI from '../hooks/useSquadraAPI';

/**
 * Gets the candidates of a shift.
 */
export async function getCandidates(shiftID) {
  try {
    const api = getSquadraAPI();
    const response = await api.get(`/shifts/${shiftID}/candidates`);
    const candidates = response.data;
    if (!candidates) {
      throw new Error(`Could not get the candidates for the shift ${shiftID}`);
    }
    return candidates;
  } catch (error) {
    logError(error);
    return;
  }
}
