import { React, useState } from 'react';
import {
  List,
  Datagrid,
  CreateButton,
  TopToolbar,
  Confirm,
  SelectInput,
  SearchInput,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import OrderedByColumn from './Columns/OrderedByColumn';
import OrderedForColumn from './Columns/OrderedForColumn';
import StatusColumn from './Columns/StatusColumn';
import OfferMailColumn from './Columns/OfferMailColumn';
import OfferMailStatusColumn from './Columns/OfferMailStatusColumn';
import StaffingMailColumn from './Columns/StaffingMailColumn';
import BillingColumn from './Columns/BillingColumn';
import ActionsColumn from './Columns/ActionsColumn';
import DateColumn from './Columns/DateColumn';
import ShiftsColumn from './Columns/ShiftsColumn';
import useOptions from '../../../hooks/useOptions';
import AutomaticBillingColumn from './Columns/AutomaticBillingColumn';

export default function OrderList() {
  const { t } = useTranslation();
  const { statuses } = useOptions();

  const [dialog, setDialog] = useState(false);

  const ListActions = () => (
    <TopToolbar>
      <CreateButton />
    </TopToolbar>
  );

  const filters = [
    <SearchInput
      source="billing.contact.fullName"
      alwaysOn
      placeholder={t('Ordered by')}
    />,
    <SelectInput
      source="status"
      label={t('Status')}
      choices={statuses}
      alwaysOn
    />,
  ];

  return (
    <List
      actions={<ListActions />}
      filters={filters}
      sort={{ field: 'createDate', order: 'DESC' }}
    >
      <Datagrid style={{ whiteSpace: 'nowrap' }} rowClick="edit">
        <DateColumn label={t('Created on')} />
        <StatusColumn label={t('Status')} />
        <OrderedByColumn label={t('Ordered by')} />
        <OrderedForColumn label={t('Ordered for')} />
        <ShiftsColumn label={t('Shifts')} />
        <OfferMailColumn label={t('Offer sent?')} setDialog={setDialog} />
        <OfferMailStatusColumn label={t('Accepted?')} />
        <StaffingMailColumn
          label={t('Staffing info sent?')}
          setDialog={setDialog}
        />
        <BillingColumn label={t('Billed?')} />
        <AutomaticBillingColumn label={t('AutoBilling?')} />
        <ActionsColumn label={t('Actions')} setDialog={setDialog} />
      </Datagrid>
      {dialog ? <Confirm {...dialog} onClose={() => setDialog(false)} /> : null}
    </List>
  );
}
