import { logError } from '../../logging';
import getSquadraAPI from '../hooks/useSquadraAPI';

/**
 * Gets the shift types of a branch.
 */
export async function getShiftTypes(branchID) {
  try {
    if (!branchID) {
      throw new Error('Branch ID not found.');
    }
    const api = getSquadraAPI();
    const shiftTypes = await api.get(`shift-types?branch=${branchID}`);
    return shiftTypes;
  } catch (e) {
    logError(e);
    return;
  }
}
