import { Alert } from '@mui/material';
import { useState } from 'react';

export default function Banner({ severity, label, show, closable }) {
  const [visible, setVisible] = useState(show);
  return (
    visible && (
      <div style={{ width: '100%', margin: '10px 10px' }}>
        <Alert
          {...(closable ? { onClose: () => setVisible(false) } : {})}
          severity={severity}
        >
          {label}
        </Alert>
      </div>
    )
  );
}
