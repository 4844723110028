import { Edit } from 'react-admin';
import { useRecordContext } from 'react-admin';
import OrderForm from './OrderForm';
import * as React from 'react';

export default function EditOrderForm() {
  return (
    <Edit redirect={false} title={<OrderTitle />} mutationMode="pessimistic">
      <OrderForm />
    </Edit>
  );
}

function OrderTitle() {
  const record = useRecordContext();
  const { fullName, firstName, lastName } = record?.billing?.contact || {};
  if (!record) {
    return null;
  }
  return <span>{`${fullName || firstName + ' ' + lastName}`}</span>;
}
