import { Check, Close } from '@mui/icons-material';
import { FunctionField } from 'react-admin';

export default function OfferMailStatusColumn() {
  return (
    <FunctionField
      render={record =>
        record?.confirmedAt ? (
          <Check color="success" />
        ) : (
          <Close color="disabled" />
        )
      }
    />
  );
}
