import { useEffect, useState } from 'react';
import { getSkills } from '../api/skills';

/**
 * Returns list of skills of care4kids.
 */
export default function useSkills() {
  const [skills, setSkills] = useState();

  useEffect(() => {
    getSkills().then(response => setSkills(response?.data || []));
  }, []);

  return skills;
}
