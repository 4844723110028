import { FunctionField, TextField, WrapperField } from 'react-admin';

export default function OrderedForColumn() {
  const orderedForHotel = (
    <WrapperField>
      <TextField
        sx={{ marginRight: '5px' }}
        source="details.recipient.firstName"
      />
      <TextField source="details.recipient.lastName" />
    </WrapperField>
  );

  const orderedForPartyEvents = (
    <WrapperField>
      <TextField
        sx={{ marginRight: '5px' }}
        source="details.eventContact.firstName"
      />
      <TextField source="details.eventContact.lastName" />
    </WrapperField>
  );

  const orderedForPrivate = (
    <WrapperField>
      <TextField
        sx={{ marginRight: '5px' }}
        source="billing.contact.firstName"
      />
      <TextField source="billing.contact.lastName" />
    </WrapperField>
  );

  return (
    <FunctionField
      render={record => {
        const { category } = record?.details || {};
        switch (category) {
          case 'hotel':
            return orderedForHotel;
          case 'partyEvents':
            return orderedForPartyEvents;
          case 'private':
            return orderedForPrivate;
          default:
            return;
        }
      }}
    />
  );
}
