import {
  ArrayInput,
  BooleanInput,
  Button,
  Confirm,
  DateField,
  FormDataConsumer,
  Labeled,
  NumberInput,
  SimpleFormIterator,
  TextField,
  TextInput,
  useRefresh,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import CostTable from '../../../components/CostTable';
import { Check } from '@mui/icons-material';
import { useMemo, useState } from 'react';
import { updateOrder } from '../../../api/orders';
import { useFormContext } from 'react-hook-form';
import { checkShiftsApproval } from '../../../utils/order';
import Loader from '../../../components/Loader';

export default function BillingTab({ record }) {
  const { t } = useTranslation();
  const refresh = useRefresh();
  const { setValue } = useFormContext();

  const [dialog, setDialog] = useState();
  const [isLoading, setLoading] = useState(false);

  const { shouldBillAutomatically, details, billing, status, id } =
    record || {};
  const { items: billingItems, bexioInvoiceID } = billing || {};
  const { shifts } = details || {};

  const areAllShiftsApproved = useMemo(
    () => shifts?.length && checkShiftsApproval(shifts),
    [shifts],
  );
  const isBilled = status === 'billed';
  const isBilling = status === 'completed';
  const billingItemsExist = billingItems?.length;
  const canBillManually =
    !shouldBillAutomatically &&
    billingItemsExist &&
    !(isBilling || isBilled || isLoading);

  const dates = (
    <div>
      {isBilled ? (
        <FormDataConsumer>
          {({ formData }) =>
            formData?.billedAt ? (
              <Labeled label={t('Billed on')}>
                <DateField source="billedAt" label="" disabled={true} />
              </Labeled>
            ) : null
          }
        </FormDataConsumer>
      ) : null}
    </div>
  );

  const bexioID = bexioInvoiceID ? (
    <div style={styling.columnContainer}>
      <Labeled label={t('Invoice ID')}>
        <TextField
          source="billing.bexioInvoiceID"
          defaultValue={''}
          label=""
          disabled={true}
        />
      </Labeled>
    </div>
  ) : null;

  const automaticBillingToggle = (
    <BooleanInput
      source="shouldBillAutomatically"
      label={t('Send invoice automatically when all shifts are approved')}
      value={shouldBillAutomatically}
      onChange={e => {
        if (e.target.checked) {
          const hasConfirmed = window.confirm(
            t(
              'Are you sure that you want to turn on automatic billing for this order? The invoice will be automatically sent to the client when all shifts have been approved.',
            ),
          );
          setValue('shouldBillAutomatically', hasConfirmed);
        }
      }}
      disabled={isBilled || areAllShiftsApproved}
    />
  );

  const billingItemsList = (
    <>
      {!billingItemsExist && <p>{t('No billing items yet.')}</p>}
      <ArrayInput label="" source="billing.items" defaultValue={[]}>
        <SimpleFormIterator
          inline
          disableAdd={isBilled}
          disableRemove={isBilled}
          addButton={<Button label={t('Add item')} />}
          removeButton={<Button color="error" label={t('Remove item')} />}
          sx={{
            '.RaSimpleFormIterator-line': {
              border: 'none',
            },
          }}
        >
          <TextInput source={'label'} label={t('Label')} disabled={isBilled} />
          <NumberInput
            source={'unitPrice'}
            label={t('Unit price (CHF)')}
            format={val => val && Number(val.toFixed(2))}
            disabled={isBilled}
            min={1}
          />
          <NumberInput
            source={'amount'}
            label={t('Amount')}
            disabled={isBilled}
            min={1}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );

  const billingItemsPrices = billingItemsExist ? (
    <CostTable items={billingItems} />
  ) : null;

  const billingButton = billingItemsExist ? (
    <Button
      color="primary"
      variant="contained"
      label={
        isLoading || isBilling
          ? t('Billing...')
          : billingItemsExist && !isBilled
          ? t('Send invoice')
          : t('Billed')
      }
      onClick={async () => {
        setDialog({
          isOpen: true,
          title: t('Ready to bill'),
          content: t('Are you sure you want to bill the client?'),
          onConfirm: async () => {
            setLoading(true);
            // Set status as completed which automatically creates and sends invoice on the backend.
            await updateOrder(id, { status: 'completed' });
            setLoading(false);
            refresh();
            setDialog(false);
          },
        });
      }}
      disabled={!canBillManually}
      style={{ marginTop: '20px' }}
      alignIcon="right"
    >
      {isBilled ? (
        <Check color="success" />
      ) : (
        (isLoading || isBilling) && <Loader />
      )}
    </Button>
  ) : null;

  return (
    <>
      {dates}
      {bexioID}
      {automaticBillingToggle}
      {billingItemsList}
      {billingItemsPrices}
      {billingButton}
      <Confirm {...dialog} onClose={() => setDialog(false)} />
    </>
  );
}

const styling = {
  columnContainer: {
    display: 'block',
    width: '100%',
  },
};
