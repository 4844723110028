import { Chip } from '@mui/material';
import { format } from 'date-fns';
import { FunctionField } from 'react-admin';

export default function ShiftsColumn() {
  return (
    <FunctionField
      render={record => {
        const { shifts } = record?.details || {};
        if (shifts?.length) {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '10px',
              }}
            >
              {shifts.map((shift, index) => (
                <Chip
                  key={`${shift.label}-${index}`}
                  color="default"
                  label={`${format(shift.start, 'E, d. MMM, HH:mm')} - ${format(
                    shift.end,
                    'HH:mm',
                  )}`}
                />
              ))}
            </div>
          );
        }
      }}
    />
  );
}
