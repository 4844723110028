import {
  ArrayInput,
  Button,
  FormDataConsumer,
  NumberInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import CostTable from '../../../components/CostTable';
import CopyOfferButton from '../../../components/buttons/CopyOfferButton';
import { isEqual } from 'lodash';

export default function OfferTab({ record }) {
  const { t } = useTranslation();
  const { offer, status } = record || {};
  const { items } = offer || {};

  const isBilled = status === 'billed';

  const offerItemsList = (
    <ArrayInput label="" source="offer.items">
      <SimpleFormIterator
        inline
        disableAdd={isBilled}
        disableRemove={isBilled}
        defaultValue={[]}
        addButton={<Button label={t('Add item')} />}
        removeButton={<Button color="error" label={t('Remove item')} />}
        sx={{
          '.RaSimpleFormIterator-line': {
            border: 'none',
          },
        }}
      >
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            const item = items?.find(i => isEqual(i, scopedFormData));
            return (
              <div style={styling.rowContainer}>
                <TextInput
                  source={getSource('label')}
                  label={t('Label')}
                  disabled={item}
                />
                <NumberInput
                  source={getSource('unitPrice')}
                  label={t('Unit price (CHF)')}
                  format={val => val && Number(val.toFixed(2))}
                  min={1}
                  disabled={item}
                />
                <NumberInput
                  source={getSource('amount')}
                  label={t('Amount')}
                  disabled={item}
                  min={1}
                />
                <CopyOfferButton source={getSource('label')} />
              </div>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );

  const offerItemPrices = items?.length ? <CostTable items={items} /> : null;

  return (
    <>
      {offerItemsList}
      {offerItemPrices}
    </>
  );
}

const styling = {
  rowContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    gap: '15px',
    padding: '5px 0px',
    alignItems: 'flex-start',
  },
};
