import { useTranslation } from 'react-i18next';
import { getPrices } from '../utils/prices';

export default function CostTable({ items }) {
  const { t } = useTranslation();
  const { subTotal, vatOfSubTotal, total } = getPrices(items);

  return (
    <table width="30%" style={styling.tableContainer}>
      <tbody>
        <tr>
          <td style={styling.tableItem}>{t('Sub-total')}</td>
          <td style={{ ...styling.tableItem }}>{`CHF ${subTotal.toFixed(
            2,
          )}`}</td>
        </tr>
        <tr>
          <td style={styling.tableItem}>{t('Vat (8.1%)')}</td>
          <td style={{ ...styling.tableItem }}>{`CHF ${vatOfSubTotal.toFixed(
            2,
          )}`}</td>
        </tr>
        <tr>
          <td style={styling.tableItem}>{t('Total')}</td>
          <td
            style={{ ...styling.tableItem, ...styling.bold }}
          >{`CHF ${total.toFixed(2)}`}</td>
        </tr>
      </tbody>
    </table>
  );
}

const styling = {
  tableContainer: {
    borderCollapse: 'collapse',
  },
  tableItem: {
    borderBottom: '1px solid lightGray',
    padding: '5px',
  },
  bold: {
    fontWeight: 'bold',
  },
};
