import { Check, Close } from '@mui/icons-material';
import { useRecordContext } from 'react-admin';
import Loader from '../../../../components/Loader';

export default function BillingColumn() {
  const record = useRecordContext();

  const { status } = record || {};

  const isBilled = status === 'billed';
  const isBilling = status === 'completed';

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        width: '100%',
      }}
    >
      {isBilling ? (
        <Loader />
      ) : isBilled ? (
        <Check color="success" />
      ) : (
        <Close color="disabled" />
      )}
    </div>
  );
}
