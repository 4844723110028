import { Alert } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Button, useRefresh } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { createShift, makeShiftData } from '../../squadra/api/shifts';
import { getCandidates } from '../../squadra/api/candidates';
import { createJob, getJob } from '../../squadra/api/jobs';
import { updateOrder } from '../../api/orders';
import { v4 as uuidv4 } from 'uuid';
import { useFormContext } from 'react-hook-form';
import { isEqual } from 'lodash';
import { logError } from '../../logging';
import Loader from '../Loader';

/**
 * A button to create multiple jobs.
 * If a shift index is passed, a single job will be created for that shift.
 * Otherwise, a job will be created for each shift.
 */
export default function CreateJobsButton({
  order,
  shiftIndex,
  isLoading,
  setLoading,
}) {
  const { t } = useTranslation();
  const refresh = useRefresh();

  const companyID = process.env.REACT_APP_SQUADRA_COMPANY_ID;
  const branchID = process.env.REACT_APP_SQUADRA_BRANCH_ID;
  const createdBy = process.env.REACT_APP_CRISTA_SQUADRA_USER_ID;

  // Get the dirty shifts fields to keep track if the changes have been saved.
  const { formState } = useFormContext();
  const { shifts: dirtyShiftsFields } = formState.dirtyFields?.details || {};
  const { squadraJobGroupID, details } = order || {};
  const { category, shifts } = details || {};

  const isSingleJob = shiftIndex >= 0;
  const shiftsToCreate = isSingleJob ? [shifts[shiftIndex]] : shifts;

  // Get the jobIDs from shift(s):
  const jobIDs = useMemo(
    () =>
      isSingleJob
        ? shifts[shiftIndex]?.squadraJobID
          ? [shifts[shiftIndex].squadraJobID]
          : []
        : shifts?.length
        ? shifts.filter(s => s.squadraJobID).map(s => s.squadraJobID)
        : [],
    [isSingleJob, shiftIndex, shifts],
  );

  const [jobs, setJobs] = useState(null);
  const [loadingText, setLoadingText] = useState('');

  // Get the jobs:
  useEffect(() => {
    if (jobIDs?.length === shiftsToCreate?.length) {
      Promise.all(jobIDs.map(jobID => getJob(jobID))).then(data =>
        setJobs(data),
      );
    } else {
      setJobs([]);
    }
  }, [jobIDs, shiftsToCreate?.length]);

  if (jobs === null) {
    return <Loader />;
  }

  // Check if users have filled out all the necessary fields to create a job:
  const canCreateJobs = !shiftsToCreate?.some(
    ({ start, end, numberOfNannies, place, type }) =>
      !(start && end && numberOfNannies && place && type),
  );
  // Check if users have saved their changes before allowing job creation.
  const hasSavedChanges = !dirtyShiftsFields?.length;
  const hasCreatedAllJobs = !!jobs?.length;
  const hasCreatedSomeJobs = !!(
    jobIDs?.length && jobIDs?.length < shifts?.length
  );

  // Creates shift and job then return their IDs and c4k shift index:
  const handleJobCreation = async (care4KidsShift, jobGroupID) => {
    // Create shift:
    setLoadingText(t('Creating shift(s)...'));
    const shiftData = makeShiftData(
      {
        ...care4KidsShift,
        company: companyID,
        branch: branchID,
        createdBy,
      },
      order,
    );
    const squadraShiftID = await createShift(shiftData);
    if (!squadraShiftID) {
      throw new Error('Could not create shift.');
    }
    const shift = { id: squadraShiftID, ...shiftData };
    // Get the candidates for this shift:
    setLoadingText(t('Finding the best candidates...'));
    const candidates = await getCandidates(squadraShiftID);
    if (!candidates) {
      throw new Error(
        `Could not get candidates for the shift ${squadraShiftID}`,
      );
    }
    // Filter the employees with suitability of 1:
    const employeeIDs = candidates
      .filter(({ suitability }) => suitability === 1)
      .map(({ employeeID }) => employeeID);
    // Create job:
    setLoadingText(t('Creating job(s)...'));
    const squadraJobID = await createJob(
      shift,
      employeeIDs,
      category,
      jobGroupID,
      order,
      createdBy,
    );
    if (!squadraJobID) {
      throw new Error(`Could not create job for shift ${squadraShiftID}`);
    }
    // Get the index if not a single job:
    const index =
      shiftIndex || shifts.findIndex(shift => isEqual(shift, care4KidsShift));
    return { shiftIndex: index, squadraShiftID, squadraJobID };
  };

  // Creates multiple shifts and jobs from shifts then updates the order:
  const handleMultipleJobCreation = async shiftGroups => {
    try {
      setLoading(true);
      const jobGroupID = squadraJobGroupID || uuidv4();
      const indexesShiftIDsAndJobIDs = await Promise.all(
        shiftGroups.map(shift => handleJobCreation(shift, jobGroupID)),
      );
      // Map through c4k shifts and update the squadra shift and jobIDs:
      const shiftsWithShiftIDsAndJobIDs = shifts.map((shift, index) => {
        const indexShiftIDAndJobID = indexesShiftIDsAndJobIDs.find(
          ({ shiftIndex }) => shiftIndex === index,
        );
        return {
          ...shift,
          ...(indexShiftIDAndJobID
            ? {
                squadraShiftID: indexShiftIDAndJobID.squadraShiftID,
                squadraJobID: indexShiftIDAndJobID.squadraJobID,
              }
            : {}),
        };
      });
      await updateOrder(order.id, {
        'details.shifts': shiftsWithShiftIDsAndJobIDs, // Update the orders with the updated shifts since individual array manipulation is not possible in firestore:
        ...(!squadraJobGroupID ? { squadraJobGroupID: jobGroupID } : {}), // If there is no squadraJobGroupID add a new jobGroupID to the order.
      });
      refresh(); // Refresh the screen after finishing all process.
    } catch (error) {
      logError(error);
    } finally {
      setLoading(false);
    }
  };

  function getSquadraJobsLink() {
    const baseLink = `https://app.squadra.work/companies/${companyID}/jobs`;
    const linkProps = {
      href: isSingleJob
        ? `${baseLink}/${jobs[0].id}`
        : `${baseLink}?group=${squadraJobGroupID}`,
      style: { textDecoration: 'none', fontWeight: 'bold' },
      target: '_blank',
      rel: 'noreferrer',
    };
    return isSingleJob ? (
      <p>
        {jobs[0].isPublished
          ? t('Job is published.')
          : t('Job created as draft.')}{' '}
        <a {...linkProps}>
          {jobs[0].isPublished ? t('View here') : t('Publish it')}
        </a>
      </p>
    ) : (
      <a {...linkProps}>{t('See all jobs in Squadra')}</a>
    );
  }

  function getCreateJobsButton() {
    const buttonProps = {
      variant: 'outlined',
      color: 'primary',
      alignIcon: 'right',
      disabled: isLoading || !canCreateJobs || !hasSavedChanges,
      label: isSingleJob
        ? isLoading
          ? loadingText
          : t('Create job')
        : isLoading
        ? loadingText
        : hasCreatedSomeJobs
        ? t('Create Squadra jobs for remaining shifts')
        : t('Create Squadra jobs for all shifts'),
    };
    return (
      shifts?.length && (
        <Button
          {...buttonProps}
          onClick={() => {
            // If there were some jobs already created, filter out the ones which were not:
            handleMultipleJobCreation(
              hasCreatedSomeJobs
                ? shiftsToCreate?.filter(s => !s.squadraJobID)
                : shiftsToCreate,
            );
          }}
        >
          {isLoading ? <Loader /> : null}
        </Button>
      )
    );
  }

  return (
    <div style={{ margin: '5px 0px' }}>
      {!hasSavedChanges && !isSingleJob && (
        <Alert severity="error" style={{ margin: '10px 0px' }}>
          {t('You must save changes to create jobs.')}
        </Alert>
      )}
      {hasCreatedAllJobs ? getSquadraJobsLink() : getCreateJobsButton()}
    </div>
  );
}
