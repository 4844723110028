import { logError } from '../../logging';
import getSquadraAPI from '../hooks/useSquadraAPI';

/**
 * Gets all the shifts of a company.
 */
export async function getShifts() {
  try {
    const api = getSquadraAPI();
    return await api.get(`/shifts?`);
  } catch (e) {
    logError(e);
    return;
  }
}

/**
 * Creates a shift in Squadra.
 */
export async function createShift(shiftData) {
  try {
    const api = getSquadraAPI();
    const response = await api.post(`/shifts`, shiftData);
    const shiftID = response.data;
    if (!shiftID) {
      throw new Error('Could not create shift in Squadra');
    }
    return shiftID;
  } catch (e) {
    logError(e);
    return;
  }
}

export function makeShiftData(values, order) {
  const {
    company,
    branch,
    createdBy,
    start,
    end,
    place,
    numberOfNannies,
    type,
    requiredSkills,
    includesTravelTime,
    travelTime,
    minimumPaidDuration,
  } = values;
  const { billing, details } = order;
  const { category } = details;
  const { contact } = billing;
  const { firstName, lastName, email } = contact || {};
  // Set external reviews only for private and hotels.
  const requiresExternalReview = category === 'private' || category === 'hotel';
  return {
    company,
    branch,
    createdBy,
    start: typeof start === 'object' ? new Date(start.toDate()) : start,
    end: typeof end === 'object' ? new Date(end.toDate()) : end,
    type,
    requiredSkills,
    place,
    note: makeShiftNote(order, place),
    positions: {
      total: numberOfNannies || 1,
      open: numberOfNannies || 1,
    },
    requiresExternalReview,
    /**
     * We must add the external reviewer regardless. Take the following use case as an example :
     *
     * A shift is created from c4k to Squadra and requiresExternalReview is false.
     * Later on in Squadra, they change their mind and edited the requiresExternalReview to
     * true, now the external reviewer for care4kids is usually the person that
     * placed the order. We don't have that data in Squadra but only in c4k and
     * hence the shift form should be populated by the externalReviewer which is already in the shift doc in Squadra.
     */
    externalReviewer: {
      email,
      firstName,
      lastName,
    },
    ...(includesTravelTime ? { includesTravelTime, travelTime } : {}),
    ...(minimumPaidDuration ? { minimumPaidDuration } : {}),
    deleted: false,
  };
}

function makeShiftNote(order, place) {
  const { details, billing } = order || {};
  const {
    category,
    children,
    recipient,
    hasPets,
    petDetails,
    eventContact,
    withPartyBox,
    meetingDetails,
    numberOfChildren,
    ageOfOldestChild,
    otherImportantInfo,
    ageOfYoungestChild,
    serviceLanguage,
  } = details || {};
  const { contact } = billing || {};
  const { firstName, lastName, phone, company } = contact || {};
  const isEnglish = serviceLanguage === 'en';
  const contactPerson =
    category === 'partyEvents'
      ? eventContact
      : category === 'hotel'
      ? billing.contact
      : contact;
  const parts = [
    `${isEnglish ? 'Orderer' : 'Einsatzbetrieb'} : ${
      company || `${firstName} ${lastName}`
    }`,
    `${
      isEnglish ? 'Contact person' : 'Kontaktperson'
    }: ${`${contactPerson.firstName} ${contactPerson.lastName}, ${phone}`}`,
    `${
      category === 'hotel'
        ? `${isEnglish ? 'Guest family name' : 'Name der Gastfamilie'}: ${
            recipient.lastName
          }`
        : ''
    }`,
    `${isEnglish ? 'Place' : 'Ort'} : ${place.name}`, // Place is a required field, always defined.
    `${isEnglish ? 'Meeting point' : 'Treffpunkt'} : ${meetingDetails}`,
    `${isEnglish ? 'Children' : 'Kinder'}: ${
      numberOfChildren > 4
        ? `${numberOfChildren}, ${ageOfOldestChild} - ${ageOfYoungestChild}`
        : children?.map(c => `${c.name} ${c.age}`).join(',')
    }`,
    `${
      withPartyBox
        ? isEnglish
          ? 'Party bag: Yes'
          : 'Spiel- und Bastelköfferli: ja'
        : ''
    }`,
    `${hasPets ? `${isEnglish ? 'Pets' : 'Haustiere'}: ${petDetails}` : ''}`,
    `${
      otherImportantInfo
        ? `${
            isEnglish ? 'Important information' : 'Wichtige Informationen'
          }: ${otherImportantInfo}`
        : ''
    }`,
  ];
  return parts
    .filter(Boolean) // Remove empty lines
    .join('\r\n'); // New lines
}
