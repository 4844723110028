import {
  ArrayInput,
  FormDataConsumer,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  required,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import useOptions from '../../../hooks/useOptions';

export default function ContactsTab({ record }) {
  const { t } = useTranslation();

  const { salutations, languages } = useOptions();

  const { status } = record || {};

  const isBilled = status === 'billed';

  const serviceLanguages = ['en', 'de'].map(l =>
    languages.find(lang => lang.id === l),
  );

  const orderedBySalutation = (
    <div style={styling.rowContainer}>
      <FormDataConsumer>
        {({ formData }) => {
          const { category } = formData?.details || {};
          const isHotel = category === 'hotel';
          const isPrivate = category === 'private';
          return isPrivate ? (
            <SelectInput
              source="billing.contact.salutation"
              choices={salutations}
              label={t('Salutation')}
              validate={required()}
              defaultValue={''}
              disabled={isBilled}
            />
          ) : (
            <>
              <TextInput
                source="billing.contact.company"
                label={isHotel ? t('Hotel') : t('Company')}
                {...(isHotel ? { validate: required() } : {})}
                defaultValue={''}
                disabled={isBilled}
              />
              <TextInput
                source="billing.contact.role"
                label={t('Role')}
                {...(isHotel ? { validate: required() } : {})}
                defaultValue={''}
                disabled={isBilled}
              />
            </>
          );
        }}
      </FormDataConsumer>
    </div>
  );

  const orderedByNames = (
    <div style={styling.rowContainer}>
      <TextInput
        source="billing.contact.firstName"
        label={t('First name')}
        validate={required()}
        disabled={isBilled}
      />
      <TextInput
        source="billing.contact.lastName"
        label={t('Last name')}
        validate={required()}
        disabled={isBilled}
      />
    </div>
  );

  const orderedByAddress = (
    <>
      <div style={styling.rowContainer}>
        <TextInput
          source="billing.contact.address"
          label={t('Address')}
          validate={required()}
          disabled={isBilled}
        />
      </div>
      <div style={styling.rowContainer}>
        <TextInput
          source="billing.contact.postalCode"
          label={t('Postal code')}
          validate={required()}
          disabled={isBilled}
        />
        <TextInput
          source="billing.contact.city"
          label={t('City')}
          validate={required()}
          disabled={isBilled}
        />
      </div>
    </>
  );

  const orderedByContact = (
    <div style={styling.rowContainer}>
      <TextInput
        source="billing.contact.phone"
        label={t('Phone')}
        validate={required()}
        disabled={isBilled}
      />
      <TextInput
        source="billing.contact.email"
        label={t('Email')}
        validate={required()}
        disabled={isBilled}
      />
    </div>
  );

  const orderedByLanguage = (
    <div style={styling.columnContainer}>
      <SelectInput
        source="details.serviceLanguage"
        label={t('Language for billing')}
        choices={serviceLanguages}
        validate={required()}
        disabled={isBilled}
      />
    </div>
  );

  // Changes_
  const additionalEmails = (
    <div style={styling.rowContainer}>
      <ArrayInput
        source="billing.contact.additionalEmails"
        label={t('Additional emails')}
        defaultValue={[]}
      >
        <SimpleFormIterator
          disableReordering
          disableAdd={isBilled}
          disableRemove={isBilled}
          sx={{ '.RaSimpleFormIterator-line': { border: 'none' } }}
        >
          <TextInput label={t('Email')} disabled={isBilled} defaultValue={''} />
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  );

  const orderedByFields = (
    <>
      <h2>{t('Ordered by')}</h2>
      {orderedBySalutation}
      {orderedByNames}
      {orderedByAddress}
      {orderedByContact}
      {orderedByLanguage}
      {/* Changes_ */}
      {additionalEmails}
    </>
  );

  const orderedForHotelFields = (
    <>
      <h2>{t('Ordered for')}</h2>
      <div style={styling.rowContainer}>
        <SelectInput
          source="details.recipient.salutation"
          choices={salutations}
          label={t('Salutation')}
          validate={required()}
          defaultValue={''}
          disabled={isBilled}
        />
      </div>
      <div style={styling.rowContainer}>
        <TextInput
          source="details.recipient.firstName"
          label={t('First name')}
          defaultValue={''}
          disabled={isBilled}
        />
        <TextInput
          source="details.recipient.lastName"
          label={t('Last name')}
          validate={required()}
          defaultValue={''}
          disabled={isBilled}
        />
      </div>
    </>
  );

  const orderedForPartyEventsFields = (
    <>
      <h2>{t('Contact person during event')}</h2>
      <div style={styling.rowContainer}>
        <TextInput
          source="details.eventContact.salutation"
          choices={salutations}
          label={t('Salutation')}
          validate={required()}
          defaultValue={''}
          disabled={isBilled}
        />
      </div>
      <div style={styling.rowContainer}>
        <TextInput
          source={'details.eventContact.firstName'}
          label={t('First name')}
          validate={required()}
          defaultValue={''}
          disabled={isBilled}
        />
        <TextInput
          source={'details.eventContact.lastName'}
          label={t('Last name')}
          validate={required()}
          defaultValue={''}
          disabled={isBilled}
        />
      </div>
      <div>
        <div style={styling.rowContainer}>
          <TextInput
            source="details.eventContact.phone"
            label={t('Phone')}
            validate={required()}
            defaultValue={''}
            disabled={isBilled}
          />
        </div>
      </div>
    </>
  );

  const orderedForPrivateFields = (
    <>
      <h2>{t('Ordered for')}</h2>
      <div style={styling.rowContainer}>
        <SelectInput
          source="billing.contact.salutation"
          choices={salutations}
          label={t('Salutation')}
          validate={required()}
          defaultValue={''}
          disabled={isBilled}
        />
      </div>
      <div style={styling.rowContainer}>
        <TextInput
          source={'billing.contact.firstName'}
          label={t('First name')}
          validate={required()}
          defaultValue={''}
          disabled={isBilled}
        />
        <TextInput
          source={'billing.contact.lastName'}
          label={t('Last name')}
          validate={required()}
          defaultValue={''}
          disabled={isBilled}
        />
      </div>
    </>
  );

  const orderedForFields = (
    <FormDataConsumer>
      {({ formData }) => {
        const { category } = formData?.details || {};
        switch (category) {
          case 'hotel':
            return orderedForHotelFields;
          case 'partyEvents':
            return orderedForPartyEventsFields;
          case 'private':
            return orderedForPrivateFields;
          default:
            return;
        }
      }}
    </FormDataConsumer>
  );

  return (
    <>
      {orderedByFields}
      {orderedForFields}
    </>
  );
}

const styling = {
  rowContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    gap: '15px',
    padding: '5px 0px',
  },
  columnContainer: {
    display: 'block',
    width: '100%',
  },
};
